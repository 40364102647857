import { fetchBaseQuery } from '@reduxjs/toolkit/query'

const baseQuery = fetchBaseQuery(
  {
    baseUrl: "/api/v1",
    prepareHeaders: (headers, { getState }) => {
      return headers;
    }
  }
);

export default baseQuery;